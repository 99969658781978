import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

async function getConfig() {
  return fetch('/__/firebase/init.json')
    .then((response) => response.json())
    .catch((err) => {
      console.log(`Error reading firebase config from reserved url. ${err}. Loading from environment.`);
      const config = {
        apiKey: process.env.REACT_APP_FB_API_KEY,
        authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FB_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FB_MESSID,
        appId: process.env.REACT_APP_FB_APP_ID,
        measurementId: process.env.REACT_APP_FB_MEAS_ID,
      };
      console.log(config);
      return config;
    });
}

async function configureFirebase() {
  const config = await getConfig();
  firebase.initializeApp(config);
}

export default configureFirebase;
